import styled from 'styled-components';
import { Carousel as CarouselBase } from 'antd';
import { devices, responsive } from '../../../theme/devices';

export const Carousel = styled(CarouselBase)`
  overflow: hidden;

  ${responsive(devices('min').laptopL)} {
    max-height: 920px;
  }
`;
