import React from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import GlobalStyle from './GlobalStlye';
import theme from './theme.constants';

const accent100 = '#34516C';
const accent110 = '#BCA45E';
// const accent120 = '#353740';

export const ThemeProvider = ({ children }) => {
  const compositeTheme = { ...theme, accent100, accent110 };

  return (
    <>
      <GlobalStyle theme={compositeTheme} />
      <StyledProvider theme={compositeTheme}>{children}</StyledProvider>
    </>
  );
};
