import React from 'react';
import Iframe from 'react-iframe';
import { extractPicture } from '../../api/api.helpers';
import TitleBanner from '../../common/components/TitleBanner/TitleBanner';
import useCMSResource from '../../common/hooks/useCMSResource';
import { Container } from './DonatePage.styles';

const DonatePage = () => {
  const { global } = useCMSResource();

  const { donationIframeUrl } = global;

  const picture = extractPicture(global, 'otherBanner');

  return (
    <>
      <TitleBanner title="Donate" url={picture} />
      <Container>
        <Iframe
          url={donationIframeUrl}
          width="800px"
          height="600px"
          display="block"
          position="relative"
        />
      </Container>
    </>
  );
};

export default DonatePage;
