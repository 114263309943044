import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import colors from './colors';

// eslint-disable-next-line quotes
export const fontFamily = "'EB Garamond', serif";

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  * {
    font-family: 'EB Garamond', serif
  }

  html, body {
    height: 100%;
    min-width: 475px;
    margin: 0;
    padding: 0 !important;
    font-family: ${fontFamily}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    font-size: 3.25rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h2 {
    font-size: 2.25rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.75rem;
    margin-bottom: 5px;
  }

  p {
    font-size: 19px;
  }

  div > span {
    font-size: 17px;
    font-weight: 400;
  }

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected {
    color: ${colors.primary100};
  }

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after,
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after {
    border-bottom: 2px solid ${colors.primary100};
  }

  .ant-btn.ant-btn-primary:not(.ant-btn-primary[disabled]) {
    background: ${colors.primary100};
    border-color: ${colors.base};
  }

  .ant-menu-item-active {
    color: ${colors.base};
  }

  .ant-menu-item:hover,  .ant-menu-item-selected {
    color: ${colors.hoverOnLight} !important;
  }

  .ant-menu-item {
    color: ${colors.black100};
  }

   

  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after {
    border-bottom: 2px solid ${colors.hoverOnLight} !important;
  }

`;

export default GlobalStyle;
