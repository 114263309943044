import styled from 'styled-components/macro';
import colors from '../../../../theme/colors';
import { devices, responsive } from '../../../../theme/devices';

export const BannerItemContainer = styled.div`
  z-index: 5;

  ${responsive(devices('min').laptopM)} {
    margin-top: 16rem;
    margin-bottom: 25rem;
    margin-right: 20rem;
    margin-left: 20rem;
    position: absolute;
    margin-top: 20rem;
    top: 0px;
  }
`;

export const BannerImageContainer = styled.div`
  height: 375px;
  overflow: hidden;
  display: flex;
  align-items: center;

  .ant-image {
    width: 100%;
  }

  ${responsive(devices('min').laptopM)} {
    display: block;
    overflow: unset;
    height: unset;
  }
`;

export const BannerDetailContainer = styled.div`
  display: flex;
  align-items: center;

  ${responsive(devices('min').tablet)} {
    align-items: unset;
    flex-direction: column;
  }
`;

export const BannerLogo = styled.div`
  padding: 1.5rem;
  padding-right: 2rem;
`;

export const BannerTitle = styled.h1`
  font-size: 30px;
  color: ${colors.primary100};

  ${responsive(devices('min').tabletL)} {
    font-size: 40px;
  }

  ${responsive(devices('min').laptopM)} {
    font-size: 3.25rem;
  }
`;

export const BannerBody = styled.div`
  font-size: 20px;
  color: ${colors.primary100};
  display: none;

  ${responsive(devices('min').tablet)} {
    display: block;
  }
`;

export const BannerItemBackground = styled.div`
  background: #000000;
  height: 100%;
  padding: 2rem;
  padding-top: 1rem;

  ${responsive(devices('min').laptopM)} {
    background: #000000b3;
  }
`;
