import styled from 'styled-components';
import { devices, responsive } from '../../../theme/devices';

export const HighlightsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  justify-content: space-evenly;
  background: ${({ theme }) => theme.accent110};
  padding: 4rem;
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 4rem;
    width: 500px;
  }
  > div:not(:last-child) {
    margin-right: 0rem;
  }

  .ant-card-meta-title {
    color: #524b35;
    font-size: 36px;
    font-weight: 600;
    font-family: 'Noto Sans', sans-serif;
  }

  .ant-card-meta {
    justify-content: center;
  }

  .ant-card-hoverable:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 36%), 0 3px 6px 0 rgb(0 0 0 / 32%),
      0 5px 12px 4px rgb(0 0 0 / 29%);
  }

  ${responsive(devices('min').laptop)} {
    flex-direction: row;
    > * {
      margin-bottom: 0rem;
      width: 400px;
    }
    > div:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;
