export const MENU_SYSTEM = {
  home: {
    label: 'Home',
    key: 'home',
    route: '/',
    anchor: 'main-layout',
  },

  sacraments: {
    label: 'Sacraments',
    key: 'sacraments',
    route: 'sacraments',
  },
  massTimes: {
    label: 'Mass Times',
    key: 'massTimes',
    route: '/',
    anchor: 'mass-times',
  },
  newsletter: {
    label: 'Newsletter',
    key: 'newsletter',
    route: '/newsletter',
  },
  donate: {
    label: 'Donate',
    key: 'donate',
    route: '/donate',
  },
  info: {
    label: 'Information',
    key: 'info',
    route: '/info',
    anchor: 'main-layout',
  },
  gallery: {
    label: 'Gallery',
    key: 'gallery',
    route: '/gallery',
    anchor: 'main-layout',
  },
  contact: {
    label: 'Contact',
    key: 'contact',
  },
};

export const menuList = Object.values(MENU_SYSTEM).map((menu) => menu);
