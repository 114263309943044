import React from 'react';
import { TagLineStyle, TagLineStyleAuthor } from './Tagline.styles';

const TagLine = ({ tagLine }) => (
  <TagLineStyle>
    {tagLine?.tagLine}
    {tagLine?.author && (
      <TagLineStyleAuthor>{tagLine?.author}</TagLineStyleAuthor>
    )}
  </TagLineStyle>
);

export default TagLine;
