/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5000;
  background: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ fadeAway }) =>
    fadeAway
      ? `
  
  opacity: 0;
  transition: opacity .6s ease-in-out;
  `
      : undefined}
`;
