import React from 'react';
import { Image } from 'antd';
import {
  BannerImageContainer,
  BannerItemContainer,
  BannerTitle,
  BannerBody,
  BannerDetailContainer,
  BannerItemBackground,
  BannerLogo,
} from './BannerItem.styles';
import Box from '../../Box/Box';

const BannerItem = ({ banner, logoSmall }) => (
  <Box relative>
    <BannerImageContainer>
      <Image preview={false} src={banner.src} />
    </BannerImageContainer>
    <BannerItemContainer className="banner-item">
      <BannerItemBackground>
        <Box flex>
          <BannerLogo>
            <Image preview={false} style={{ width: '100%' }} src={logoSmall} />
          </BannerLogo>
          <BannerDetailContainer>
            <BannerTitle>{banner.title}</BannerTitle>
            <BannerBody>{banner.brief}</BannerBody>
          </BannerDetailContainer>
        </Box>
      </BannerItemBackground>
    </BannerItemContainer>
  </Box>
);

export default BannerItem;
