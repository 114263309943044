import { Footer as FooterBase } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const FooterStyle = styled(FooterBase)`
  background: ${({ theme }) => theme.accent100};
  color: ${({ theme }) => theme.colors.textOnDark};

  .ant-menu-vertical {
    border-right: 0px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${({ theme }) => theme.colors.black100};
  }

  .ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.base} !important;
  }
`;

export const SocialLinkStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin-top: 15px;
  }
  a:not(:first-child) {
    margin-left: 15px;
  }
`;
