import colors from './colors';
import spacings from './spacings';

const theme = {
  spacings,
  colors,
  backgroundColor: colors.base,
  button: {
    primary: {
      background: colors.accent100,
      borderRadius: '4px',
      border: 'none',
      color: colors.accent110,
      hoverBackground: colors.accent110,
      activeBackground: colors.accent120,
    },
    secondary: {
      background: 'transparent',
      color: colors.accent100,
      borderRadius: '4px',
      border: `1px solid ${colors.accent100}`,
      hoverBackground: colors.hover,
      activeBackground: colors.pressed,
    },
    tertiary: {
      background: 'transparent',
      color: colors.textOnLight,
      borderRadius: '4px',
      border: 'none',
      hoverBackground: colors.hover,
      activeBackground: colors.pressed,
    },
    link: {
      background: 'transparent',
      color: '#00000',
      borderRadius: 'none',
      border: 'none',
      hoverBackground: 'none',
      activeBackground: 'none',
    },
    ghost: {
      background: 'transparent',
      color: colors.accent100,
      borderRadius: 'none',
      border: 'none',
      hoverBackground: colors.hover,
      activeBackground: colors.pressed,
    },
  },
  typography: {
    color: colors.textOnLight,
  },
};

export default theme;
