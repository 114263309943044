import styled from 'styled-components';
import { devices, responsive } from '../../../theme/devices';

export const SectionActionContainer = styled.div`
  margin: 6rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionActionBody = styled.div`
  font-size: 19px;
  margin-bottom: 2rem;

  ${responsive(devices('min').laptopL)} {
    width: 800px;
  }
`;
