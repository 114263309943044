import styled from 'styled-components';

export const Button = styled.button`
  color: #ffffff !important;
  border-width: 0px !important;
  border-radius: 0px;
  font-size: 16px;
  cursor: pointer;
  background-color: #29526f;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;
