import React from 'react';
import { Card } from 'antd';

const { Meta } = Card;

export const HighlightItem = ({ title, image, onClick }) => (
  <Card
    onClick={onClick}
    hoverable
    cover={<img alt={title} src={`images/${image}`} />}
  >
    <Meta title={title} />
  </Card>
);
