import React from 'react';

const ContactAddress = ({ children }) => (
  <div style={{ maxWidth: '275px', marginLeft: '2rem' }}>
    <h2 style={{ marginBottom: '.5rem' }}>Contact Us</h2>
    {children}
  </div>
);

export default ContactAddress;
