import React from 'react';
import Banner from '../../common/components/Banner/Banner';
import SectionAction from '../../common/components/SectionAction/SectionAction';
import MassTimes from '../../common/components/MassTimes/MassTimes';
import TagLine from '../../common/components/TagLine/TagLine';
import Highlights from '../../common/components/Highlights/Highlights';
import useCMSResource from '../../common/hooks/useCMSResource';
import NoticeSection from '../../common/components/NoticeSection/NoticeSection';

const Home = () => {
  const { noticeSection, sections, tagLines } = useCMSResource();

  return (
    <>
      <Banner />
      <SectionAction
        title={sections.homeSection1?.title}
        body={sections.homeSection1?.description}
      />

      <MassTimes />
      <TagLine tagLine={tagLines.homeTag1} />

      {noticeSection.homeNotice && (
        <NoticeSection notice={noticeSection.homeNotice} />
      )}

      {tagLines.homeTag2 && <TagLine tagLine={tagLines.homeTag2} />}

      <Highlights />

      <SectionAction
        title={sections.homeSection2?.title}
        body={sections.homeSection2?.description}
      />
    </>
  );
};

export default Home;
