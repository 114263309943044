import styled from 'styled-components/macro';
import { Button } from 'antd';
import { devices, responsive } from '../../theme/devices';

export const Link = styled(Button)`
  margin: 10px;
  border-radius: 1rem;
  width: 250px;
  place-self: center;
  background-color: ${({ theme }) => theme.accent100};
  color: white;
  font-size: 19px;
  :hover,
  :active,
  :focus {
    background: ${({ theme }) => theme.accent100};
  }

  margin-top: 1rem;
  margin-bottom: 2rem;

  ${responsive(devices('min').laptop)} {
    margin-bottom: 4rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-bottom: 2rem;
  margin-top: 2rem;

  ${responsive(devices('min').laptop)} {
    display: grid;
    grid-template-columns: 500px 500px;
  }
`;
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Border = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.accent100};
  font-size: 25px;
  color: white;
`;

export const Text = styled.h3`
  font-size: 30px;
  margin: 3rem;
`;
