import styled from 'styled-components';
import colors from '../../../theme/colors';
import { devices, responsive } from '../../../theme/devices';

export const MassTimesHeading = styled.h3`
  font-size: 38px;
  color: ${colors.base};
  text-align: center;
  margin-bottom: 2rem;
`;

export const MassTimesSectionHeading = styled.div`
  font-size: 21px;
  font-weight: 600;
  color: ${colors.base};
`;

export const MassTimesSectionBody = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${colors.base};
`;

export const MassTimesWrapper = styled.div`
  color: ${colors.base};
  font-size: 2rem;
  padding: 6rem;
  background: ${({ theme }) => theme.accent100};

  ${responsive(devices('min').laptopL)} {
    padding-left: 11rem;
    padding-right: 11rem;
  }
`;

export const MassTimesSectionContainer = styled.div`
  border-top: ${colors.secondaryAccent105} solid 1px;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  margin-right: 0rem;

  ${responsive(devices('min').laptop)} {
    margin-right: 3rem;
  }
`;

export const MassTimesContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;
