const process = {
  env: {
    REACT_APP_GOOGLE_MAPS_API: 'AIzaSyButjgshqAFUGDaEHwqM18uu_rnm3kCV3o',
  },
};

export const ENV_KEYS = {
  googleApi: 'REACT_APP_GOOGLE_MAPS_API',
};

export const getEnvironmentVars = () => {
  Object.values(ENV_KEYS).forEach((value) => {
    if (!process.env[value]) {
      // eslint-disable-next-line no-console
      console.error(`Unable to find environment variable ${value}`);
    }
  });

  return Object.entries(ENV_KEYS).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: process.env[value],
    }),
    {}
  );
};

const envVars = Object.freeze(getEnvironmentVars());

export default envVars;
