import * as contentful from 'contentful';
// import { log } from '../common/util/loggerUtil';
import {
  resolveDomainConfiguration,
  first,
  extractFields,
  parseArray,
  addLoaded,
} from './api.helpers';

export const contentfulConfig = resolveDomainConfiguration();

const client = contentful.createClient({
  ...contentfulConfig,
});

export const globalApi = client
  .getEntries({ content_type: 'global' })
  .then(extractFields)
  .then(first)
  .then(addLoaded);

export const contactApi = client
  .getEntries({ content_type: 'contact' })
  .then(extractFields)
  .then(first)
  .then(addLoaded);

export const footerApi = client
  .getEntries({ content_type: 'footer' })
  .then(extractFields)
  .then(first)
  .then(addLoaded);

export const newsletterApi = client
  .getEntries({ content_type: 'newsletter', order: '-fields.date' })
  .then(extractFields);

export const galleryApi = client
  .getEntries({ content_type: 'gallery' })
  .then(extractFields)
  .then(first)
  .then(addLoaded);

export const massTimesApi = client
  .getEntries({ content_type: 'massTimes', order: 'fields.position' })
  .then(extractFields);

export const bannerApi = client
  .getEntries({ content_type: 'banner', order: 'sys.createdAt' })
  .then(extractFields);

export const blogApi = client
  .getEntries({ content_type: 'blog' })
  .then(extractFields)
  .then(parseArray);

export const noticeSectionApi = client
  .getEntries({ content_type: 'noticeSection' })
  .then(extractFields)
  .then(parseArray);

export const sectionActionApi = client
  .getEntries({ content_type: 'sectionAction' })
  .then(extractFields)
  .then(parseArray);

export const tagLineActionApi = client
  .getEntries({ content_type: 'taglines' })
  .then(extractFields)
  .then(parseArray);
