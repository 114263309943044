import React from 'react';
import BannerItem from './Components/BannerItem';
import { Carousel } from './Banner.styles';
import useCMSResource from '../../hooks/useCMSResource';
import { extractPicture } from '../../../api/api.helpers';

const Banner = () => {
  const { banners, global, loaded } = useCMSResource();

  if (!loaded) {
    return null;
  }

  const logoSrc = extractPicture(global, 'logoSmall');

  return (
    <Carousel>
      {banners?.map((banner) => {
        const src = extractPicture(banner, 'image');
        return (
          <BannerItem
            key={banner.id}
            logoSmall={logoSrc}
            banner={{ ...banner, src }}
          />
        );
      })}
    </Carousel>
  );
};

export default Banner;
