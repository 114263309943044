const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '875px',
  laptop: '1024px',
  laptopM: '1200px',
  laptopL: '1540px',
  desktop: '2560px',
};

export const devices = (context = 'min') => ({
  mobileS: `(${context}-width: ${sizes.mobileS})`,
  mobileM: `(${context}-width: ${sizes.mobileM})`,
  mobileL: `(${context}-width: ${sizes.mobileL})`,
  tablet: `(${context}-width: ${sizes.tablet})`,
  tabletL: `(${context}-width: ${sizes.tabletL})`,
  laptop: `(${context}-width: ${sizes.laptop})`,
  laptopM: `(${context}-width: ${sizes.laptopM})`,
  laptopL: `(${context}-width: ${sizes.laptopL})`,
  desktop: `(${context}-width: ${sizes.desktop})`,
});

export const responsive = (device) => `@media ${device}`;
