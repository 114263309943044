export const log = (...args) => {
  const { hostname } = window.location;

  // eslint-disable-next-line operator-linebreak
  const shouldLog =
    localStorage.getItem('log') || hostname.toLowerCase() === 'localhost';

  // eslint-disable-next-line no-console
  return shouldLog ? console.log(args) : false;
};
