import styled from 'styled-components';

export const TitleBannerContainer = styled.div`
  overflow: hidden;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleBannerText = styled.div`
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
`;
