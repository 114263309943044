import styled from 'styled-components/macro';
import { Image } from 'antd';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;

export const Picture = styled(Image)``;

export const PictureContainer = styled.div`
  overflow: hidden;
  margin: 2rem;
`;
