import styled from 'styled-components/macro';
import { devices, responsive } from '../../../theme/devices';

export const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  ${responsive(devices('min').laptop)} {
    flex-direction: row;
  }
`;

export const ItemContainer = styled.div`
  flex: 1;
  background-color: #353740;
`;

export const Text = styled.h3`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'cursor')};
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: white;
  justify-content: center;
  align-items: center;
  ${responsive(devices('min').laptop)} {
    padding-right: 5rem;
    padding-left: 5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;

export const Picture = styled.div`
  padding: 5px;
  justify-content: center;
  align-items: center;
  background: url('${({ image }) => image}');
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100%;
  min-height: 300px;
`;

export const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background-color: #201111b5;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 20px;
  margin-left: 5px;
  color: white;
  align-items: center;
`;

export const H4 = styled.h4`
  color: white;
  font-size: 20px;
  font-weight: 500;
`;
