import React from 'react';
import GoogleMapReact from 'google-map-react';
import envVars from '../../util/configUtil';
import { extractPicture } from '../../../api/api.helpers';

const MapMarker = ({ marker }) => (
  <div>
    <img src={marker} alt="goolge marker" />
  </div>
);

const GoogleMap = ({ coordinates, mapMarker }) => {
  if (!coordinates) {
    return null;
  }

  const mapMarkerUrl = extractPicture(mapMarker, 'mapMarker');

  return (
    <div style={{ height: '775px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: envVars.googleApi }}
        defaultCenter={{
          lat: coordinates.lat,
          lng: coordinates.lng,
        }}
        defaultZoom={coordinates.zoom}
      >
        <MapMarker
          marker={mapMarkerUrl}
          lat={coordinates.lat}
          lng={coordinates.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
