export const domainConfig = {
  localhost: {
    parish: 'st-dominics-downham-market',
    logo: 'st-dominics.png',
  },
  'saintdominics.org.uk': {
    parish: 'st-dominics-downham-market',
    logo: 'st-dominics.png',
  },
  'parish-a.saintdominics.org.uk': {
    parish: 'parish-a-some-location',
    logo: 'fssp_logo.png',
  },
};

export const googleMapsKey = 'AIzaSyButjgshqAFUGDaEHwqM18uu_rnm3kCV3o';

export const contentfulConfig = {
  localhost: {
    environment: 'master',
    space: 'h9n7gfwl4gj0',
    accessToken: 'SJjYp2v4Dm8qSAUbKQerO6MpTkFhgECWIw1vaeVOSsw',
    logo: 'st-dominics.png',
    // host: 'preview.contentful.com',
  },
  'stdominics.infinatech.co.uk': {
    environment: 'master',
    space: '0rdp7x4ydqwb',
    accessToken: 'gAiw3kR6uw-KkOLuwLZsbQGjfCpb9XHZW_sQSffH7kA',
  },
  'saintdominics.org.uk': {
    environment: 'master',
    space: 'h9n7gfwl4gj0',
    accessToken: 'SJjYp2v4Dm8qSAUbKQerO6MpTkFhgECWIw1vaeVOSsw',
    logo: 'st-dominics.png',
    // space: '0rdp7x4ydqwb',
    // accessToken: 'gAiw3kR6uw-KkOLuwLZsbQGjfCpb9XHZW_sQSffH7kA',
  },
};
