import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HighlightsContainer } from './Highlights.styles';
import { HighlightItem } from './components/HighlightItem';
import { highlights } from './Highlights.constants';
import { MENU_SYSTEM } from '../../../routes';

const { first, second, third } = highlights;

const Highlights = () => {
  const navigate = useNavigate();

  const navigateToPage = useCallback(
    (route) => () => {
      navigate(route);
    },
    [navigate]
  );

  return (
    <HighlightsContainer>
      <HighlightItem
        {...first}
        onClick={navigateToPage(MENU_SYSTEM.sacraments.route)}
      />
      <HighlightItem
        {...second}
        onClick={navigateToPage(MENU_SYSTEM.newsletter.route)}
      />
      <HighlightItem
        {...third}
        onClick={navigateToPage(MENU_SYSTEM.donate.route)}
      />
    </HighlightsContainer>
  );
};

export default Highlights;
