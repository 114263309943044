import React from 'react';
import { BlogContainer } from './BlogPage.styles';
import TitleBanner from '../../common/components/TitleBanner/TitleBanner';
import useCMSResource from '../../common/hooks/useCMSResource';
import MainContainer from '../../layout/MainLayout/MainContainer';
import { RichText } from '../../common/components/RichText/RichText';
import { extractPicture } from '../../api/api.helpers';

// All blog pages on Parish website
export const BlogPage = ({ blogKey }) => {
  const { blog } = useCMSResource();

  // Guard in case there are no blog pages
  if (!blog || !Object.keys(blog).length) {
    return null;
  }

  // Extract appropriate blog based on prop blogKey
  const extractBlog = blog[blogKey];

  const bannerSrc = extractPicture(extractBlog, 'banner');

  // Render response
  return (
    <>
      <TitleBanner title={extractBlog?.title} url={bannerSrc} />
      <MainContainer>
        <BlogContainer>
          <RichText richTextDocument={extractBlog?.content} />
        </BlogContainer>
      </MainContainer>
    </>
  );
};
