import React from 'react';
import { PlayCircleFilled, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  Container,
  ItemContainer,
  Text,
  Picture,
  H4,
} from './NoticeSection.styles';
import useCMSResource from '../../hooks/useCMSResource';
import { extractPicture } from '../../../api/api.helpers';

const NoticeSection = ({ notice }) => {
  const image = extractPicture(notice, 'image');

  const { global } = useCMSResource();
  const { accent120 } = global;

  const onClickButton = () => {
    window.open(notice.buttonLink);
  };

  return (
    <Container>
      <ItemContainer>
        <Picture image={`${image}`}>
          {notice.buttonLink ? (
            <Button onClick={onClickButton}>
              <PlayCircleFilled
                style={{ color: accent120, margin: '3px', fontSize: '35px' }}
              />
              <div style={{ marginLeft: '.5rem', fontSize: '24px' }}>
                {notice.buttonName}
              </div>
            </Button>
          ) : null}
        </Picture>
      </ItemContainer>
      <ItemContainer>
        <Text onClick={onClickButton} clickable={!!notice.buttonLink}>
          <h1
            style={{
              color: 'white',
            }}
          >
            {notice.title}
          </h1>
          <H4>
            {' '}
            {notice.description}
            {notice.buttonLink ? (
              <ArrowRightOutlined
                style={{ color: '#5b87a7', marginLeft: '.5rem' }}
              />
            ) : null}
          </H4>
        </Text>
      </ItemContainer>
    </Container>
  );
};

export default NoticeSection;
