import React from 'react';
import styled from 'styled-components';

export const BorderStyle = styled.div`
  background: ${({ theme }) => theme.accent110};
  height: 5px;
  width: ${({ width }) => width}px;
  margin-bottom: 20px;
`;

const Border = ({ width = 70 }) => <BorderStyle width={width} />;

export default Border;
