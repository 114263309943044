import React from 'react';

const Box = ({ display, relative, fixed, flex, children, flexDirection }) => {
  const resolvePosition = () => {
    if (relative) {
      return 'relative';
    }

    if (fixed) {
      return 'fixed';
    }

    return 'unset';
  };

  return (
    <div
      style={{
        display: flex ? 'flex' : display,
        flexDirection,
        position: resolvePosition(),
      }}
    >
      {children}
    </div>
  );
};
export default Box;
