import { useEffect, useState } from 'react';
import {
  footerApi,
  globalApi,
  bannerApi,
  noticeSectionApi,
  sectionActionApi,
  massTimesApi,
  newsletterApi,
  tagLineActionApi,
  blogApi,
  contactApi,
  galleryApi,
} from '../../api/api';

const notLoaded = { loaded: false, data: {} };

const useContentful = () => {
  const [loaded, setLoaded] = useState(false);
  const [banners, setBanners] = useState([]);
  const [newsletters, setNewsletters] = useState([]);
  const [sections, setSections] = useState([]);
  const [tagLines, setTagLines] = useState([]);
  const [blog, setBlog] = useState([]);
  const [noticeSection, setNoticeSection] = useState([]);
  const [contact, setContact] = useState(notLoaded);
  const [footer, setFooter] = useState(notLoaded);
  const [global, setGlobal] = useState(notLoaded);
  const [gallery, setGallery] = useState(notLoaded);
  const [massTimes, setMassTimes] = useState(notLoaded);

  useEffect(() => {
    galleryApi.then((response) => {
      setGallery(response ?? {});
    });

    globalApi.then((response) => {
      setGlobal(response ?? {});
    });
    footerApi.then((response) => {
      setFooter(response ?? []);
    });

    bannerApi.then((response) => {
      setBanners(response ?? []);
    });

    contactApi.then((response) => {
      setContact(response ?? {});
    });
    newsletterApi.then((response) => {
      setNewsletters(response ?? []);
    });
    blogApi.then((response) => {
      setBlog(response ?? []);
    });

    sectionActionApi.then((response) => {
      setSections(response ?? []);
    });

    noticeSectionApi.then((response) => {
      setNoticeSection(response ?? []);
    });

    massTimesApi.then((response) => {
      setMassTimes(response ?? []);
    });

    tagLineActionApi.then((response) => {
      setTagLines(response ?? []);
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const dataArray = [footer, global, contact, gallery];

    // console.log('dataArray', dataArray);
    setLoaded(!dataArray.some(({ loaded: dataLoaded }) => !dataLoaded));
    // setLoaded(true);
  }, [footer, global, contact, gallery]);

  return {
    footer,
    loaded,
    global,
    banners,
    blog,
    noticeSection,
    newsletters,
    sections,
    tagLines,
    massTimes,
    contact,
    gallery,
  };
};

export default useContentful;
