import React from 'react';
import { format } from 'date-fns';
import { Link, Container, Box, Border, Text } from './Newsletter.styles';
import TitleBanner from '../../common/components/TitleBanner/TitleBanner';
import useCMSResource from '../../common/hooks/useCMSResource';
import { extractPicture } from '../../api/api.helpers';

const NewsletterLink = ({ newsletter }) => {
  const newletter = extractPicture(newsletter, 'newsletter');
  return (
    <Link href={`${newletter}`} target="_blank">
      {format(new Date(newsletter.date), 'do MMMM yyyy')}
    </Link>
  );
};

export const Newsletter = () => {
  const { global, newsletters } = useCMSResource();
  console.log(
    '🚀 ~ file: Newsletter.js:19 ~ Newsletter ~ newsletters:',
    newsletters
  );
  console.log('🚀 ~ file: Newsletter.js:19 ~ Newsletter ~ global:', global);

  if (!newsletters?.length) {
    return null;
  }
  const picture = extractPicture(global, 'otherBanner');

  const [firstNewsletter, ...restNewsletter] = newsletters;

  return (
    <>
      <TitleBanner title="Newsletter" url={picture} />
      <Box>
        <Text>Newsletters can be downloaded from this page</Text>
        <NewsletterLink newsletter={firstNewsletter} />
        <Border>
          <div>Newsletter Archive</div>
        </Border>
        <Container>
          {restNewsletter?.map((item) => (
            <NewsletterLink key={item.id} newsletter={item} />
          ))}
        </Container>
      </Box>
    </>
  );
};
