import React, { useState } from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MenuContainer } from './AppBar.styles';
import { menuList, MENU_SYSTEM } from '../../routes';

const AppBar = ({ layoutRef }) => {
  const [current, setCurrent] = useState();

  const navigate = useNavigate();

  const slowScroll = (selector) => {
    if (!selector) {
      return;
    }

    setTimeout(() => {
      layoutRef.current
        ?.querySelector(`#${selector}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  const onClick = (e) => {
    const route = MENU_SYSTEM[e.key];

    setCurrent(e.key);

    navigate(route.route ?? `/${e.key}`);
    slowScroll(route.anchor);
  };

  return (
    <MenuContainer>
      <div>
        <Menu
          disabledOverflow
          onClick={onClick}
          mode="horizontal"
          defaultSelectedKeys={['home']}
          selectedKeys={[current]}
          items={menuList}
        />
      </div>
    </MenuContainer>
  );
};

export default AppBar;
