import styled from 'styled-components';
import colors from '../../../theme/colors';
import { devices, responsive } from '../../../theme/devices';
import { fontFamily } from '../../../theme/GlobalStlye';

export const TagLineStyleAuthor = styled.h3`
  font-size: 22px;
  text-align: center;
  color: ${({ theme }) => theme.accent110};
`;

export const TagLineStyle = styled.div`
  background: ${colors.base};
  text-align: center;
  padding: 2rem;
  font-family: ${fontFamily};
  font-size: 1.5rem;

  ${responsive(devices().tablet)} {
    font-size: 2rem;
    padding: 7rem;
  }
`;
