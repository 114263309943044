import React from 'react';
import TitleBanner from '../../common/components/TitleBanner/TitleBanner';
import useCMSResource from '../../common/hooks/useCMSResource';
import { Container, Picture, PictureContainer } from './Gallery.styles';
import { extractPicture } from '../../api/api.helpers';

export const Gallery = () => {
  const { gallery, global, loaded } = useCMSResource();

  if (!loaded) {
    return null;
  }

  const bannerPicture = extractPicture(global, 'otherBanner');

  return (
    <>
      <TitleBanner title="Gallery" url={bannerPicture} />
      <Container>
        {gallery?.images?.map((image, index) => {
          const file = extractPicture({ details: image }, 'details');
          return (
            // eslint-disable-next-line react/no-array-index-key
            <PictureContainer key={index}>
              <Picture src={file} />
            </PictureContainer>
          );
        })}
      </Container>
    </>
  );
};
