import React from 'react';
import GoogleMap from '../../common/components/GoogleMap/GoogleMap';
import ContactAddress from './Components/ContactDetails';
import TitleBanner from '../../common/components/TitleBanner/TitleBanner';
import MainContainer from '../../layout/MainLayout/MainContainer';
import { ContactContainer } from './ContactPage.styles';
import useCMSResource from '../../common/hooks/useCMSResource';
import { extractPicture } from '../../api/api.helpers';
import { RichText } from '../../common/components/RichText/RichText';

const ContactPage = () => {
  const { contact } = useCMSResource();

  const bannerUrl = extractPicture(contact, 'banner');

  return (
    <>
      <TitleBanner title="Contact" url={bannerUrl} />
      <MainContainer>
        <ContactContainer>
          <GoogleMap
            coordinates={contact.coordinates}
            mapMarker={{ mapMarker: contact.mapMarker }}
          />
          <ContactAddress>
            <RichText richTextDocument={contact.mapContent} />
          </ContactAddress>
        </ContactContainer>
      </MainContainer>
    </>
  );
};

export default ContactPage;
