import { Image } from 'antd';
import React from 'react';
import colors from '../../../theme/colors';
import Border from '../Border/Border';
import { TitleBannerContainer, TitleBannerText } from './TitleBanner.styles';

const TitleBanner = ({ title, url }) => (
  <TitleBannerContainer>
    <TitleBannerText>
      <h1 style={{ color: colors.base }}>{title}</h1>
      <Border width={170} />
    </TitleBannerText>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: colors.black100_30,
        zIndex: 5,
      }}
    />
    <Image width="100%" preview={false} src={url} />
  </TitleBannerContainer>
);

export default TitleBanner;
