export const highlights = {
  first: {
    image: 'sacraments.jpg',
    title: 'Sacraments',
  },
  second: {
    image: 'newspaper.jpg',
    title: 'Newsletter',
  },
  third: {
    image: 'money-jar.jpg',
    title: 'Donate',
  },
};
