import React from 'react';
import Border from '../Border/Border';
import { Button } from '../Button/Button.styles';
import {
  SectionActionBody,
  SectionActionContainer,
} from './SectionAction.styles';

const SectionAction = ({ title, body, action, onActionClick }) => (
  <SectionActionContainer>
    <h2>{title}</h2>
    <Border />
    <SectionActionBody>{body}</SectionActionBody>
    {action && <Button onClick={onActionClick}>{action}</Button>}
  </SectionActionContainer>
);

export default SectionAction;
