import styled from 'styled-components';
import colors from '../../theme/colors';

export const MenuContainer = styled.div`
  display: flex;
  justify-content: end;
  height: 47px;
  .ant-menu-title-content {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
  }
  > * {
    position: fixed;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: end;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    background: ${colors.base};
  }
`;
