const colors = {
  primary100: '#FFFFFF',
  accent100: '#0098FF',
  secondaryAccent90: '#EC6472',
  secondaryAccent100: '#34516c',
  secondaryAccent105: '#5f809e',
  secondaryAccent110: '#bca45e',
  secondaryAccent10: '#C7404E',
  base: '#FFFFFF',
  grey100: '#d5d5d5',
  grey110: '#D8D8DA',
  black100: '#000000',
  black100_30: '#00000030',
  primary30: '#DFE4E8',
  primary40: '#DAE0E5',
  primary90: '#244563',
  primary110: '#00213E',
  accent110: '#0082DA',
  accent120: '#006CB6',
  hover: '#F1F3F4',
  hoverOnLight: '#1890ff',
  pressed: '#E2E5E8',
  success: '#0AD98E',
  warning: '#F2CB00',
  error: '#E3001B',
  info: '#1E63B2',
  textOnLight: '#2C2A29',
  textOnDark: '#E9E9E9',
  hintTextOnDark: '#AAAAAA',
};

export default colors;
