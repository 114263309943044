import { Col, Image, Row } from 'antd';
import React from 'react';
import { FooterStyle } from './Footer.styles';
import { fontFamily } from '../../theme/GlobalStlye';
import spacings from '../../theme/spacings';
import useCMSResource from '../../common/hooks/useCMSResource';
import { extractPicture } from '../../api/api.helpers';

const Footer = () => {
  const { loaded, footer, global } = useCMSResource();

  if (!loaded) {
    return null;
  }

  const logoSmall = extractPicture(global, 'logoSmall');

  return (
    <FooterStyle>
      <Row>
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '1rem',
            }}
          >
            <Image
              preview={false}
              src={logoSmall}
              style={{ marginBottom: '2rem' }}
            />
            <div
              style={{ fontFamily, fontSize: 20, textTransform: 'uppercase' }}
            >
              {global.parishName}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={6}>
          <div style={{ paddingRight: spacings.F14, fontSize: 18 }}>
            <b>
              {footer?.content?.map((item, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={idx}>{item}</div>
              ))}
            </b>
          </div>
        </Col>
        <Col xs={24} lg={12} style={{ textAlign: 'center', fontSize: '18px' }}>
          <b>{global.contactEmail}</b>
        </Col>
        <Col xs={24} lg={6}>
          {/* <MenuList
            background={colors.black100}
            color={colors.base}
            mode="vertical"
          /> */}
        </Col>
      </Row>
    </FooterStyle>
  );
};

export default Footer;
