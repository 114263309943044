import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import ContactPage from './pages/Contact/ContactPage';
import { ThemeProvider } from './theme/themeProvider';
import { Newsletter } from './pages/Newsletter/Newsletter';
import MainLayout from './layout/MainLayout/MainLayout';
import DonatePage from './pages/Donate/DonatePage';
import { BlogPage } from './pages/Blog/BlogPage';
import { Gallery } from './pages/Gallery/Gallery';
import Preloader from './common/components/Preloader/Preloader';
// import Head from './common/components/Head/Head';
import useCMSResource from './common/hooks/useCMSResource';
import './api/apollo';
import './App.css';

function App() {
  const { loaded } = useCMSResource();
  return (
    <ThemeProvider>
      <Preloader loaded={loaded} />
      <MainLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/sacraments"
            element={<BlogPage blogKey="sacraments" />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/donate" element={<DonatePage />} />
          <Route path="/info" element={<BlogPage blogKey="information" />} />
        </Routes>
      </MainLayout>
    </ThemeProvider>
  );
}

export default App;
