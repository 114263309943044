const spacings = {
  U1: 4,
  U2: 8,
  U3: 12,
  U4: 16,
  U5: 20,
  U6: 24,
  U7: 28,
  U8: 32,
  U9: 36,
  U10: 40,
  U11: 44,
  U12: 48,
  U13: 52,
  U14: 56,
  F1: 4,
  F2: 8,
  F3: 12,
  F4: 16,
  F5: 20,
  F6: 24,
  F7: 28,
  F8: 32,
  F9: 36,
  F10: 40,
  F11: 44,
  F12: 48,
  F13: 52,
  F14: 56,
};

export default spacings;
