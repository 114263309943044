import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import AppBar from '../AppBar/AppBar';

const MainLayout = ({ children }) => {
  const layoutRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div ref={layoutRef}>
      <div id="main-layout" />
      <AppBar layoutRef={layoutRef} />
      {children}
      <Footer />
    </div>
  );
};
export default MainLayout;
