import styled from 'styled-components';
import { devices, responsive } from '../../theme/devices';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 2rem;

  ${responsive(devices('min').laptop)} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1400px;
  }
`;
