import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { contentfulConfig } from '../../../api/api';
import { Cover } from './Preloader.styles';

const Preloader = ({ loaded }) => {
  const [hidePreloader, setHidePreloader] = useState();

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setHidePreloader(true);
      }, 750);
    }
  }, [loaded]);

  return hidePreloader ? null : (
    <Cover fadeAway={loaded}>
      <Image preview={false} src={`images/${contentfulConfig.logo}`} />
    </Cover>
  );
};

export default Preloader;
