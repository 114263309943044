/* eslint-disable react/no-array-index-key */
import React from 'react';
import useCMSResource from '../../hooks/useCMSResource';
import {
  MassTimesContainer,
  MassTimesHeading,
  MassTimesSectionBody,
  MassTimesSectionContainer,
  MassTimesSectionHeading,
  MassTimesWrapper,
} from './MassTimes.styles';

const MassTimes = () => {
  const { massTimes = [] } = useCMSResource();

  if (!massTimes?.length) {
    return null;
  }

  const massTimesTitle = 'Mass and Confession Times';

  return (
    <MassTimesWrapper id="mass-times">
      <MassTimesHeading>{massTimesTitle}</MassTimesHeading>
      <MassTimesContainer>
        {(massTimes ?? []).map(({ id, title, description }, outerIndex) => (
          <React.Fragment key={`${id}-${outerIndex}`}>
            <MassTimesSectionContainer>
              <MassTimesSectionHeading>{title}</MassTimesSectionHeading>

              {description?.map((desc, index) => (
                <React.Fragment key={`${id}-${index}`}>
                  <MassTimesSectionBody>{desc}</MassTimesSectionBody>
                </React.Fragment>
              ))}
            </MassTimesSectionContainer>
          </React.Fragment>
        ))}
      </MassTimesContainer>
    </MassTimesWrapper>
  );
};

export default MassTimes;
