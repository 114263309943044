/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
/* eslint-disable arrow-body-style */
import { log } from '../common/util/loggerUtil';
import { contentfulConfig, domainConfig } from './api.conf';
// import { domainConfig, imageAssets } from './api.conf';

// export const parseArray = (array) =>
//   array.reduce((acc, item) => ({ ...acc, [item.key]: item }), {});

// export const extractField = ({ fields, sys }) => ({ ...fields, id: sys.id });

export const consoleLog = (logLabel = 'LOG') => {
  return (items) => {
    console.log(logLabel, items);
    return items;
  };
};

// export const first = (items) => items[0];

// export const wrapResponse = (data) => ({ data, loaded: true });

// export const extractFields = ({ data: { data } }) => data;

// export const extractPicture = (contentModel, pictureKey = 'picture') =>
//   `${imageAssets}${contentModel[pictureKey]}`;

// export const extractPictures = (contentModel, pictureKey = 'picture') =>
//   contentModel[pictureKey].map((element) => element.fields.file.url);

// export const extractRichText = (section, key = 'body') => {
//   const {
//     [key]: {
//       content: [
//         {
//           content: [{ value }],
//         },
//       ],
//     },
//   } = section;

//   return value;
// };

export const resolveDomainConfiguration = () => {
  const { hostname } = window.location;

  log(
    hostname.replace('www.', ''),
    domainConfig,
    domainConfig[hostname.replace('www.', '')]
  );

  return contentfulConfig[hostname.replace('www.', '')];
};

// export const resolveDomainConfiguration = () => {
//   const { hostname } = window.location;

//   log(
//     hostname.replace('www.', ''),
//     domainConfig,
//     domainConfig[hostname.replace('www.', '')]
//   );

//   return domainConfig[hostname.replace('www.', '')];
// };

export const parseArray = (array) =>
  array.reduce(
    (acc, item) => ({ ...acc, [item.identfier ?? item.key]: item }),
    {}
  );

export const extractField = ({ fields, sys }) => ({ ...fields, id: sys.id });

export const addLoaded = (obj) => ({ ...obj, loaded: true });

export const first = (items) => items[0];

export const extractFields = ({ items = [] }) => items.map(extractField);

export const extractPicture = (contentModel, pictureKey = 'picture') => {
  try {
    const {
      [pictureKey]: {
        fields: {
          file: { url },
        },
      },
    } = contentModel;

    return url;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `Unable to extract picture under key ${pictureKey}`,
      e,
      contentModel
    );
    return '';
  }
};

export const extractPictures = (contentModel, pictureKey = 'picture') =>
  contentModel[pictureKey].map((element) => element.fields.file.url);

export const extractRichText = (section, key = 'body') => {
  const {
    [key]: {
      content: [
        {
          content: [{ value }],
        },
      ],
    },
  } = section;

  return value;
};
